<template>
    <div class="admin-user-content">
        <div class="admin-user-tabler">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                <el-select style="margin-left: 20px" v-model="userTypeValue" placeholder="请选择"
                           @change="selectUserTypeChange">
                    <el-option v-for="item in userTypeGroup" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>
            <el-button type="primary" style="margin-left: 20px" @click="addUserDialogVisible=true">增加</el-button>
            <el-button type="primary" style="margin-left: 20px" @click="refresh">刷新</el-button>
        </div>
        <div class="admin_user-data-content">
            <el-table class="userTable" ref="filterTable" :data="userData" height="100%" style="width: 100%;"
                      v-loading="loading">
                <el-table-column
                        label="序号"
                        align="center"
                        type="index"
                        width="100">
                </el-table-column>
                <el-table-column align="center" prop="userName" label="用户名" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="password" label="密码" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="accessDeviceCount" label="门禁设备数量" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="doorDeviceCount" label="门柜设备数量" width="auto">
                </el-table-column>

                <el-table-column align="center"  label="公司" width="150">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>用户Token: {{ scope.row.token}}</p>
                            <p>公司名称: {{ scope.row.companyName}}</p>
                            <p>公司电话: {{ scope.row.companyPhone}}</p>
                            <p>公司地址: {{ scope.row.companyAddress}}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="type" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.type===-2 ? 'danger' : 'success'" disable-transitions>
                            {{ scope.row.type===-2 ? "封号" : "正常" }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="600">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning"
                                   @click="tableUpdateUserNameOrPassword(scope.$index, scope.row)">
                            修改
                        </el-button>
                        <el-button v-if="scope.row.allowDeleteRecord" size="mini" type="danger" @click="tableDeletionOfRecordsIsProhibited(scope.$index, scope.row)">
                            禁止删除记录
                        </el-button>
                        <el-button v-if="!scope.row.allowDeleteRecord" size="mini" type="success" @click="tableAllowDeletionOfRecords(scope.$index, scope.row)">
                            允许删除记录
                        </el-button>
                        <el-button v-if="scope.row.autoAsync" size="mini" type="danger" @click="setAutoAsync(scope.$index, scope.row,false)">
                            禁止同步
                        </el-button>
                        <el-button v-if="!scope.row.autoAsync" size="mini" type="success" @click="setAutoAsync(scope.$index, scope.row,true)">
                            允许同步
                        </el-button>
                        <el-button v-if="scope.row.type===0" size="mini" type="danger"
                                   @click="tableBanUser(scope.$index, scope.row)">
                            封号
                        </el-button>
                        <el-button v-if="scope.row.type===-2" size="mini" type="success"
                                   @click="tableUnblockUser(scope.$index, scope.row)">
                            解封
                        </el-button>
                        <el-button size="mini" type="danger" @click="tableDeleteUser(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <el-dialog title="增加用户" :visible.sync="addUserDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <span>
                <el-input v-model="addUserData.userName" placeholder="用户名"></el-input>
                <div style="margin-top: 20px;"></div>
                <el-input v-model="addUserData.password" placeholder="密码"></el-input>
                 <div style="margin-top: 20px;"></div>
                <el-input v-model="addUserData.confirmPassword" placeholder="确认密码"></el-input>
                 <div style="margin-top: 20px;width: 100%">
                     <el-button style="width: 100%" type="primary" @click="addUser">确 定</el-button>
                 </div>

            </span>

        </el-dialog>

        <el-dialog title="修改用户名或密码" :visible.sync="updateUserNameOrPasswordDialogVisible" width="20%"
                   :before-close="handleClose"
                   :modal-append-to-body="false">
            <span>
                <el-input v-model="updateUserNameOrPasswordData.userName" placeholder="用户名"></el-input>
                <div style="margin-top: 20px;"></div>
                <el-input v-model="updateUserNameOrPasswordData.newPassword" placeholder="密码"></el-input>
                 <div style="margin-top: 20px;"></div>
                <el-input v-model="updateUserNameOrPasswordData.confirmPassword" placeholder="确认密码"></el-input>
                 <div style="margin-top: 20px;width: 100%">
                     <el-button style="width: 100%" type="primary" @click="updateUserNameOrPassword">确 定</el-button>
                 </div>

            </span>

        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "user",
        data() {
            return {
                userTypeValue:'正常',
                userTypeGroup: [
                    {
                        name: '正常'
                    }, {
                        name: '封号'
                    }
                ],
                type:0,
                loading: false,
                addUserDialogVisible: false,
                updateUserNameOrPasswordDialogVisible: false,
                total: 0,
                page: 1,
                length: 10,
                userData: [],
                tempUser: {},
                addUserData: {
                    userName: '',
                    password: '',
                    confirmPassword: '',
                    type: 0
                },
                updateUserNameOrPasswordData: {
                    id: 0,
                    userId: 0,
                    userName: '',
                    newPassword: '',
                    confirmPassword: '',
                }
            }
        },
        mounted() {
            this.refresh()
        },
        methods: {
            refresh() {
                this.pagingQuery()
            },
            selectUserTypeChange(value) {
                if (value==='正常'){
                    this.type=0
                }else {
                    this.type=-2
                }
                this.refresh()
            },
            handleSizeChange(length) {
                this.length=length
                this.pagingQuery()
            },
            handleCurrentChange(page) {
                this.page=page
                this.pagingQuery()
            },
            handleClose(done) {
                done()
            },
            pagingQuery() {
                setTimeout(() => {
                    this.loading = true
                    axios.post('/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        parameter: {
                            type: this.type,
                        }
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.userData = res.data.data.content
                            this.total = res.data.data.totalElements
                        } else {
                            this.userData = []
                            this.total = 0
                        }
                    }).catch((error) => {
                        this.loading = false
                        this.userData = []
                        this.total = 0
                    })
                })
            },
            tableUpdateUserNameOrPassword(index, user) {
                this.tempUser = user
                this.updateUserNameOrPasswordData.id = user.id
                this.updateUserNameOrPasswordData.userId = user.id
                this.updateUserNameOrPasswordData.userName = user.userName
                this.updateUserNameOrPasswordDialogVisible = true
            },
            tableDeletionOfRecordsIsProhibited(index,user){
                setTimeout(()=>{
                    axios.post('/setAllowDeleteRecord',{
                        userId:user.id,
                        allowDeleteRecord:false
                    }).then((res)=>{
                        this.pagingQuery()
                    }).catch((error)=>{
                        this.$message({
                            type: 'warning',
                            message: '请求服务器失败!'
                        });
                    })
                })
            },
            tableAllowDeletionOfRecords(index,user){
                setTimeout(()=>{
                    axios.post('/setAllowDeleteRecord',{
                        userId:user.id,
                        allowDeleteRecord:true
                    }).then((res)=>{
                        this.pagingQuery()
                    }).catch((error)=>{
                        this.$message({
                            type: 'warning',
                            message: '请求服务器失败!'
                        });
                    })
                })
            },
            setAutoAsync(index,user,autoAsync){
                setTimeout(()=>{
                    axios.post('/setAutoAsync',{
                        userId:user.id,
                        autoAsync:autoAsync
                    }).then((res)=>{
                        this.pagingQuery()
                    }).catch((error)=>{
                        this.$message({
                            type: 'warning',
                            message: '请求服务器失败!'
                        });
                    })
                })
            },
            tableBanUser(index, user) {
                this.$confirm('此操作将封禁该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        axios.post('/banUser?id=' + user.userId)
                            .then((res) => {
                                if (res.data.code === 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg
                                    });
                                    this.pagingQuery()
                                } else {
                                    this.$message({
                                        type: 'warning',
                                        message: res.data.msg
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'warning',
                                    message: '请求服务器失败!'
                                });
                            })
                    })
                }).catch(() => {
                });
            },
            tableUnblockUser(index, user) {
                this.$confirm('此操作将解封该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        axios.post('/unblockUser?id=' + user.userId)
                            .then((res) => {
                                if (res.data.code === 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg
                                    });
                                    this.pagingQuery()
                                } else {
                                    this.$message({
                                        type: 'warning',
                                        message: res.data.msg
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'warning',
                                    message: '请求服务器失败!'
                                });
                            })
                    })
                }).catch(() => {
                });
            },
            tableDeleteUser(index, user) {
                this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    setTimeout(() => {
                        axios.get('/delete?id=' + user.userId)
                            .then((res) => {
                                if (res.data.code === 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg
                                    });
                                    this.pagingQuery()
                                } else {
                                    this.$message({
                                        type: 'warning',
                                        message: res.data.msg
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'warning',
                                    message: '请求服务器失败!'
                                });
                            })
                    })
                }).catch(() => {
                });
            },
            updateUserNameOrPassword() {
                this.updateUserNameOrPasswordDialogVisible = false
                setTimeout(() => {
                    this.updateUserNameOrPasswordData.id = this.tempUser.id
                    axios.post('/updateUserNameOrPassword', this.updateUserNameOrPasswordData)
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.pagingQuery()
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.data.msg
                                });
                            }
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'warning',
                                message: '请求服务器失败!'
                            });
                        })
                })
            },
            addUser() {
                this.addUserDialogVisible = false
                setTimeout(() => {
                    axios.post('/register', this.addUserData)
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.pagingQuery()
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.data.msg
                                });
                            }
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'warning',
                                message: '请求服务器失败!'
                            });
                        })
                })
            },
        }
    }
</script>

<style scoped>
    .admin-user-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

    }

    .admin-user-tabler {
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        align-items: center;
        width: 95%;
        height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        overflow-x: scroll;
    }

    .admin_user-data-content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 100%;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

</style>